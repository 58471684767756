/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Client, RequestContext } from "../client";
import type { TenantsFilterQueryParameters, MultiTenancyStatusResource, NewTenantResource, ResourceCollection, TagTestResult, TenantMissingVariableResource, TenantsMissingVariablesBffResponse, TenantResource, TenantVariableResource, GetTenantsOverviewBffResponse, GetTenantDetailBffResponse, GetTenantsSummariesBffResponse, GetAllTenantSummariesAcrossAllSpacesResponse, TenantSummary, ModifyTenantCommonVariablesCommand, GetTenantProjectVariablesByTenantIdResponse, GetTenantCommonVariablesByTenantIdResponse, ModifyTenantProjectVariablesCommand, } from "../resources";
import type { AllArgs, ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";
type TenantListArgs = {
    projectId?: string;
} & ListArgs;
type TenantAllArgs = {
    projectId?: string;
} & AllArgs;
type ListTenantsArgs = {
    projectId?: string;
    tags?: string;
    clonedFromTenantId?: string;
    name?: string;
} & ListArgs;
export class TenantRepository extends BasicRepository<TenantResource, NewTenantResource, TenantListArgs, TenantAllArgs> {
    constructor(client: Client, requestContext: RequestContext) {
        super("Tenants", client, requestContext);
    }
    status(): Promise<MultiTenancyStatusResource> {
        return this.client.get(this.requestContext, this.client.getLink("TenantsStatus"));
    }
    tagTest(tenantIds: string[], tags: string[]): Promise<TagTestResult> {
        return this.client.get(this.requestContext, this.client.getLink("TenantTagTest"), { tenantIds, tags });
    }
    getVariables(tenant: TenantResource): Promise<TenantVariableResource> {
        return this.client.get(this.requestContext, tenant.Links["Variables"]);
    }
    setVariables(tenant: TenantResource, variables: TenantVariableResource): Promise<TenantVariableResource> {
        return this.client.put(this.requestContext, tenant.Links["Variables"], variables);
    }
    missingVariables(filterOptions: FilterOptions = {}, includeDetails: boolean = false): Promise<TenantMissingVariableResource[]> {
        const payload = {
            environmentId: filterOptions.environmentId,
            includeDetails: !!includeDetails,
            projectId: filterOptions.projectId,
            tenantId: filterOptions.tenantId,
        };
        return this.client.get(this.requestContext, this.client.getLink("TenantsMissingVariables"), payload);
    }
    missingVariablesBff(projectIds: string[], tenantIds: string[], environmentIds: string[]): Promise<TenantsMissingVariablesBffResponse> {
        const payload = {
            environmentIds: environmentIds,
            projectIds: projectIds,
            tenantIds: tenantIds,
        };
        return this.client.post(this.requestContext, "~/bff/spaces/{spaceId}/tenants/variables-missing", payload);
    }
    list(args?: ListTenantsArgs): Promise<ResourceCollection<TenantResource>> {
        return this.client.get(this.requestContext, this.client.getLink("Tenants"), { ...args });
    }
    tenantsOverview(pageNumber: number, pageSize: number, filter: TenantsFilterQueryParameters): Promise<GetTenantsOverviewBffResponse> {
        const args = { pageNumber, pageSize, ...filter };
        return this.client.get(this.requestContext, "~/bff/spaces/{spaceId}/tenants/overview{?pageNumber,pageSize,filterByName,filterByTags,filterByProject,filterByEnvironment,filterByExcludedTags,filterByExcludedProject,filterByExcludedEnvironment,filterByExcludedName,includeMissingVariablesStatus}", args);
    }
    tenantsCsv(filter: TenantsFilterQueryParameters): void {
        const args = { ...filter };
        const csvUrl = this.client.resolveUrlWithSpaceId("~/bff/spaces/{spaceId}/tenants/csv{?filterByName,filterByTags,filterByProject,filterByEnvironment,filterByExcludedTags,filterByExcludedProject,filterByExcludedEnvironment,filterByExcludedName,includeMissingVariablesStatus}", args);
        window.location.assign(csvUrl);
    }
    tenantDetail(tenantId: string, includeMissingVariables: boolean): Promise<GetTenantDetailBffResponse> {
        return this.client.get(this.requestContext, "~/bff/spaces/{spaceId}/tenants/detail/{tenantId}{?includeMissingVariablesStatus}", { tenantId, includeMissingVariablesStatus: includeMissingVariables });
    }
    tenantsSummaries(): Promise<GetTenantsSummariesBffResponse> {
        return this.client.get(this.requestContext, "~/bff/spaces/{spaceId}/tenants/summaries");
    }
    getVariablesByTenantId(tenantId: string): Promise<TenantVariableResource> {
        return this.client.get(this.requestContext, "~/api/{spaceId}/tenants/{id}/variables", { id: tenantId });
    }
    setVariablesByTenantId(tenantId: string, variables: TenantVariableResource): Promise<TenantVariableResource> {
        return this.client.put(this.requestContext, "~/api/{spaceId}/tenants/{id}/variables", variables, { id: tenantId });
    }
    getProjectVariablesByTenantId(tenantId: string): Promise<GetTenantProjectVariablesByTenantIdResponse> {
        return this.client.get(this.requestContext, "~/api/{spaceId}/tenants/{id}/projectvariables", { id: tenantId });
    }
    setProjectVariablesByTenantId(tenantId: string, variables: ModifyTenantProjectVariablesCommand): Promise<ModifyTenantProjectVariablesCommand> {
        return this.client.put(this.requestContext, "~/api/{spaceId}/tenants/{id}/projectvariables", variables, { id: tenantId });
    }
    getCommonVariablesByTenantId(tenantId: string): Promise<GetTenantCommonVariablesByTenantIdResponse> {
        return this.client.get(this.requestContext, "~/api/{spaceId}/tenants/{id}/commonvariables", { id: tenantId });
    }
    setCommonVariablesByTenantId(tenantId: string, variables: ModifyTenantCommonVariablesCommand): Promise<ModifyTenantCommonVariablesCommand> {
        return this.client.put(this.requestContext, "~/api/{spaceId}/tenants/{id}/commonvariables", variables, { id: tenantId });
    }
    async summariesAcrossAllSpaces(args?: {
        filterByName?: string;
        filterBySpace?: string;
        filterByExcludedSpace?: string;
        filterByTags?: string[];
        filterByExcludedTags?: string[];
    }): Promise<TenantSummary[]> {
        const response = await this.client.get<GetAllTenantSummariesAcrossAllSpacesResponse>(this.requestContext, `~/bff/tenants/summaries{?filterByName,filterBySpace,filterByExcludedSpace,filterByTags,filterByExcludedTags}`, args);
        return response.Tenants;
    }
}
type FilterOptions = {
    tenantId?: string;
    projectId?: string;
    environmentId?: string;
};
