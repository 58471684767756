import type { Client, RequestContext } from "../client";
import type { UseDefaultBranch, BranchSpecifier } from "../repositories/projectRepository";
import type { PagingCollection, GitBranchResource, GitTagResource, GitRef, ValidateGitRefV2Response, PlatformHubAccountResource, GitCredentialResource } from "../resources/index";
export class PlatformHubRepository {
    protected readonly client: Client;
    protected readonly context: RequestContext;
    constructor(client: Client, requestContext: RequestContext) {
        this.client = client;
        this.context = requestContext;
    }
    async tryGetBranch(defaultBranch: string, selectedRef: string): Promise<GitBranchResource | null> {
        type UseDefaultBranch = typeof UseDefaultBranch;
        function ShouldUseDefaultBranch(branch: BranchSpecifier): branch is UseDefaultBranch {
            return typeof branch === "object";
        }
        try {
            const branchName: string = ShouldUseDefaultBranch(selectedRef) ? defaultBranch : selectedRef;
            return await this.client.get(this.context, "~/bff/platformhub/branches/{/name}", { name: branchName });
        }
        catch (ex) {
            if (ex.StatusCode === 404) {
                return null;
            }
            throw ex;
        }
    }
    getBranches() {
        return this.client.get<PagingCollection<GitBranchResource>>(this.context, "~/bff/platformhub/branches");
    }
    searchBranches(partialBranchName: string): Promise<PagingCollection<GitBranchResource>> {
        return this.client.get(this.context, "~/bff/platformhub/branches{?searchByName}", { searchByName: partialBranchName });
    }
    searchTags(partialBranchName: string): Promise<PagingCollection<GitTagResource>> {
        return this.client.get(this.context, "~/bff/platformhub/tags{?searchByName}", { searchByName: partialBranchName });
    }
    getTags(): Promise<PagingCollection<GitTagResource>> {
        return this.client.get(this.context, "~/bff/platformhub/tags");
    }
    validateGitRef(gitRef: GitRef): Promise<ValidateGitRefV2Response> {
        return this.client.post(this.context, "~/bff/platformhub/validate", { GitRef: gitRef });
    }
    createBranch(newBranchName: string, baseGitRef: GitRef): Promise<GitBranchResource> {
        return this.client.post<GitBranchResource>(this.context, "~/bff/platformhub/branches", { NewBranchName: newBranchName, BaseGitRef: baseGitRef });
    }
    getAllAccounts() {
        return this.client.get<PlatformHubAccountResource[]>(this.context, "~/bff/platformhub/accounts/all");
    }
    createAccount(account: PlatformHubAccountResource) {
        return this.client.post<PlatformHubAccountResource>(this.context, "~/bff/platformhub/accounts", account);
    }
    getAccountById(accountId: string) {
        return this.client.get<PlatformHubAccountResource>(this.context, "~/bff/platformhub/accounts/{id}", { id: accountId });
    }
    modifyAccount(account: PlatformHubAccountResource) {
        return this.client.put<PlatformHubAccountResource>(this.context, "~/bff/platformhub/accounts/{accountId}", account, { accountId: account.Id });
    }
    deleteAccount(account: PlatformHubAccountResource) {
        return this.client.delete(this.context, "~/bff/platformhub/accounts/{accountId}", null, { accountId: account.Id });
    }
    createGitCredential(gitCredential: GitCredentialResource) {
        return this.client.post<GitCredentialResource>(this.context, "~/bff/platformhub/git-credentials", gitCredential);
    }
    modifyGitCredential(gitCredential: GitCredentialResource) {
        return this.client.put<GitCredentialResource>(this.context, `~/bff/platformhub/git-credentials/${gitCredential.Id}`, gitCredential);
    }
    deleteGitCredential(gitCredential: GitCredentialResource) {
        return this.client.del(this.context, "~/bff/platformhub/git-credentials/{gitCredentialId}", null, { gitCredentialId: gitCredential.Id });
    }
    getGitCredentials(args?: GetGitCredentialsArgs) {
        return this.client.get<PagingCollection<GitCredentialResource>>(this.context, "~/bff/platformhub/git-credentials{?skip,take,name}", args);
    }
    getGitCredentialById(gitCredentialId: string) {
        return this.client.get<GitCredentialResource>(this.context, "~/bff/platformhub/git-credentials/{gitCredentialId}", { gitCredentialId });
    }
}
type GetGitCredentialsArgs = {
    skip?: number;
    take?: number;
    name?: string;
};
