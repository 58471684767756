import { css } from "@emotion/css";
import { LinearProgress, SearchInput } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import type { ListElement } from "../List/List";
import { useComposedRefs } from "../composeRefs";
interface DropdownSwitcherListLayoutProps {
    searchPlaceholder: string;
    searchAccessibleName: string;
    searchValue: string;
    onSearchValueChanged?: (value: string) => void;
    actionButton?: React.ReactNode;
    renderList: (ref: React.Ref<ListElement>) => React.ReactNode;
    isLoading: boolean;
    errorAlert: React.ReactNode | undefined;
}
export const DropdownSwitcherListLayout = React.forwardRef<ListElement, DropdownSwitcherListLayoutProps>(({ searchPlaceholder, searchAccessibleName, searchValue, onSearchValueChanged, actionButton, renderList, isLoading, errorAlert }, ref) => {
    const listRef = React.useRef<ListElement>(null);
    const composedRefs = useComposedRefs(listRef, ref);
    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "ArrowDown") {
            listRef.current?.focus();
            event.preventDefault();
        }
    };
    return (<div className={containerStyles}>
            {onSearchValueChanged && (<div className={headerStyles} onKeyDown={onKeyDown}>
                    <SearchInput placeholder={searchPlaceholder} value={searchValue} onChange={onSearchValueChanged} autoFocus={true} accessibleName={searchAccessibleName} fullWidth={true}/>
                    {actionButton}
                </div>)}
            <div className={contentStyles}>
                {errorAlert}
                {renderList(composedRefs)}
            </div>
            <div className={progressStyles}>
                <LinearProgress variant={"indeterminate"} show={isLoading}/>
            </div>
        </div>);
});
DropdownSwitcherListLayout.displayName = "DropdownSwitcherListLayout";
const containerStyles = css({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: "100%",
});
const headerStyles = css({
    display: "flex",
    gap: space[16],
    padding: space[16],
});
const contentStyles = css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    // The space[4] padding top is required to allow for the item focus rings to show without clipping
    padding: `${space[4]} ${space[16]} ${space[16]} ${space[16]}`,
    overflow: "auto",
    gap: space[4],
});
const progressStyles = css({
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
});
