import type { Client, RequestContext } from "../client";
import type { GetKubernetesResourceManifestByIdBffResponse, GetKubernetesResourceManifestsResponse } from "../resources/kubernetesResourceManifestResource";
import type { GetKubernetesResourceManifestSummariesResponse } from "../resources/kubernetesResourceManifestSummaryResource";
export class KubernetesManifestRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    getKubernetesManifest(manifestId: string) {
        return this.client.get<GetKubernetesResourceManifestByIdBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/kubernetesmanifests/{id}", {
            id: manifestId,
        });
    }
    getKubernetesManifestsForDeployment(deploymentId: string) {
        return this.client.get<GetKubernetesResourceManifestsResponse>(this.requestContext, "~/bff/spaces/{spaceId}/kubernetesmanifests?deploymentId={deploymentId}", {
            deploymentId,
        });
    }
    getKubernetesManifestsForRunbookRun(runbookRunId: string) {
        return this.client.get<GetKubernetesResourceManifestsResponse>(this.requestContext, "~/bff/spaces/{spaceId}/kubernetesmanifests?runbookRunId={runbookRunId}", {
            runbookRunId,
        });
    }
    getKubernetesManifestSummariesForDeployment(deploymentId: string) {
        return this.client.get<GetKubernetesResourceManifestSummariesResponse>(this.requestContext, "~/bff/spaces/{spaceId}/kubernetesmanifestsummaries?deploymentId={deploymentId}", {
            deploymentId,
        });
    }
    getKubernetesManifestSummariesForRunbookRun(runbookRunId: string) {
        return this.client.get<GetKubernetesResourceManifestSummariesResponse>(this.requestContext, "~/bff/spaces/{spaceId}/kubernetesmanifestsummaries?runbookRunId={runbookRunId}", {
            runbookRunId,
        });
    }
}
