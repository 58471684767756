import type { SensitiveValue } from "./propertyValueResource";
import type { ResourceWithId } from "./resource";
export interface SmtpConfigurationResource extends ResourceWithId {
    SmtpHost: string;
    SmtpPort?: number;
    SendEmailFrom: string;
    EnableSsl: boolean;
    SmtpLogin?: string;
    SmtpPassword: SensitiveValue;
    Timeout?: number;
    CredentialType: SmtpAuthenticationType;
    GoogleAudience?: string;
    GoogleServiceAccount?: string;
}
export enum SmtpAuthenticationType {
    None = "None",
    UsernamePassword = "UsernamePassword",
    GoogleOAuth = "GoogleOAuth"
}
export function IsUsingSmtpUsernamePasswordAuth(model: SmtpConfigurationResource): boolean {
    return model.CredentialType === SmtpAuthenticationType.UsernamePassword;
}
export function IsUsingSmtpGoogleOAuthAuth(model: SmtpConfigurationResource): boolean {
    return model.CredentialType === SmtpAuthenticationType.GoogleOAuth;
}
