import type { Client, RequestContext } from "../client";
import type { DeploymentTimelineBffResponse, CreateDeploymentFreezeOverrideResponse, DeploymentResource, CreateBulkDeploymentBffCommand, CreateBulkDeploymentBffResponse, CreateDeploymentFreezeOverrideCommand, CreateDeploymentResourceWithOverrides, } from "../resources";
import type { GetDeploymentProcessActionTypesBffResponse } from "../resources/getDeploymentProcessActionTypesBffResponse";
import type { GetDeploymentSummaryBffResponse } from "../resources/getDeploymentSummaryBffResponse";
import type { TaskState } from "../resources/taskState";
import type { ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";
type DeploymentListArgs = {
    projects?: string[];
    environments?: string[];
    tenants?: string[];
    channels?: string[];
    taskState?: TaskState;
} & ListArgs;
export class DeploymentRepository extends BasicRepository<DeploymentResource, DeploymentResource, DeploymentListArgs> {
    constructor(client: Client, requestContext: RequestContext) {
        super("Deployments", client, requestContext);
    }
    override create(resource: DeploymentResource, args?: {}): Promise<DeploymentResource> {
        return this.client.post<DeploymentResource>(this.requestContext, this.client.getLink(this.collectionLinkName), resource, args);
    }
    createDeploymentFreezeOverride(command: CreateDeploymentFreezeOverrideCommand) {
        return this.client.post<CreateDeploymentFreezeOverrideResponse>(this.requestContext, `~/api/{spaceId}/deployments/override`, command);
    }
    createBulkDeployment(deployments: CreateDeploymentResourceWithOverrides[], formValues: {
        [p: string]: string;
    } | null): Promise<CreateBulkDeploymentBffResponse> {
        const deployment = deployments[0];
        const command: CreateBulkDeploymentBffCommand = {
            ReleaseId: deployment.ReleaseId,
            ProjectId: deployment.ProjectId,
            ForcePackageDownload: deployment.ForcePackageDownload,
            ForcePackageRedeployment: deployment.ForcePackageRedeployment,
            SkipActions: deployment.SkipActions,
            UseGuidedFailure: deployment.UseGuidedFailure,
            DebugMode: deployment.DebugMode,
            FormValues: formValues,
            QueueTime: deployment.QueueTime,
            QueueTimeExpiry: deployment.QueueTimeExpiry,
            RequestedDeployments: deployments.map((d) => ({
                EnvironmentId: d.EnvironmentId,
                TenantId: d.TenantId,
                SpecificMachineIds: d.SpecificMachineIds,
                ExcludedMachineIds: d.ExcludedMachineIds,
                OverrideDeploymentFreezeIds: d.OverrideDeploymentFreezeIds,
                OverrideReason: d.OverrideReason,
            })),
        };
        return this.client.post<CreateBulkDeploymentBffResponse>(this.requestContext, `~/bff/spaces/{spaceId}/bulkdeployment`, command);
    }
    getDeploymentTimeline(projectId: string, environmentId: string, tenantId?: string, skip?: number, take?: number): Promise<DeploymentTimelineBffResponse> {
        const filterArgs = { tenantId, skip, take };
        const results = this.client.get<DeploymentTimelineBffResponse>(this.requestContext, `~/bff/spaces/{spaceId}/projects/${projectId}/Environments/${environmentId}/deployments/timeline{?tenantId,skip,take}`, filterArgs);
        return results;
    }
    getDeploymentProcessActionTypes(deploymentProcessId: string): Promise<GetDeploymentProcessActionTypesBffResponse> {
        return this.client.get<GetDeploymentProcessActionTypesBffResponse>(this.requestContext, `~/bff/spaces/{spaceId}/deploymentProcess/actionTypes?deploymentProcessId=${encodeURIComponent(deploymentProcessId)}`);
    }
    getDeploymentSummaryForTask(deploymentId: string): Promise<GetDeploymentSummaryBffResponse> {
        return this.client.get<GetDeploymentSummaryBffResponse>(this.requestContext, `~/bff/spaces/{spaceId}/deployments/summary/${deploymentId}`);
    }
}
