import { AuthenticationRepository } from "./authentication/authenticationRepository";
import type { Client, RequestContext } from "./client";
import { SettingsRepository } from "./configuration/settingsRepository";
import { ExperiencesConfigurationRepository } from "./repositories/ExperiencesConfigurationRepository";
import { AccountRepository } from "./repositories/accountRepository";
import { ActionTemplateRepository } from "./repositories/actionTemplateRepository";
import { ArtifactRepository } from "./repositories/artifactRepository";
import { AuditArchiveRepository } from "./repositories/auditArchiveRepository";
import { AuditStreamConfigurationRepository } from "./repositories/auditStreamConfigurationRepository";
import { BlueprintRepository } from "./repositories/blueprintRepository";
import { BuildInformationRepository } from "./repositories/buildInformationRepository";
import { CertificateConfigurationRepository } from "./repositories/certificateConfigurationRepository";
import { CertificateRepository } from "./repositories/certificateRepository";
import { ChannelRepository } from "./repositories/channelRepository";
import { CloudTemplateRepository } from "./repositories/cloudTemplateRepository";
import { CommunityActionTemplateRepository } from "./repositories/communityActionTemplateRepository";
import { DashboardConfigurationRepository } from "./repositories/dashboardConfigurationRepository";
import { DashboardRepository } from "./repositories/dashboardRepository";
import { DefectRepository } from "./repositories/defectRepository";
import { DeploymentFreezesRepository } from "./repositories/deploymentFreezesRepository";
import { DeploymentRepository } from "./repositories/deploymentRepository";
import { DeprecationRepository } from "./repositories/deprecationRepository";
import { DiagnosticsRepository } from "./repositories/diagnosticsRepository";
import { DynamicExtensionRepository } from "./repositories/dynamicExtensionRepository";
import { DynamicFormRepository } from "./repositories/dynamicFormRepository";
import { EnvironmentRepository } from "./repositories/environmentRepository";
import { EventRepository } from "./repositories/eventRepository";
import { ExternalSecurityGroupProviderRepository } from "./repositories/externalSecurityGroupProviderRepository";
import { ExternalSecurityGroupRepository } from "./repositories/externalSecurityGroupRepository";
import { ExternalUsersRepository } from "./repositories/externalUsersRepository";
import { FeatureToggleRepository } from "./repositories/featureToggleRepository";
import { FeaturesConfigurationRepository } from "./repositories/featuresConfigurationRepository";
import { FeedRepository } from "./repositories/feedRepository";
import { GitCredentialRepository } from "./repositories/gitCredentialRepository";
import { GitHubAppRepository } from "./repositories/gitHubAppRepository";
import { IconRepository } from "./repositories/iconRepository";
import { ImportExportActions } from "./repositories/importExportActions";
import { InAppMessagingRepository } from "./repositories/inAppMessagingRepository";
import { InsightsReportRepository } from "./repositories/insightsReportRepository";
import { InstallationHistoryRepository } from "./repositories/installationHistoryRepository";
import { InterruptionRepository } from "./repositories/interruptionRepository";
import { InvitationRepository } from "./repositories/inviteRepository";
import { KubernetesLiveStatusRepository } from "./repositories/kubernetesLiveStatusRepository";
import { KubernetesManifestRepository } from "./repositories/kubernetesManifestRepository";
import { LetsEncryptConfigurationRepository } from "./repositories/letsEncryptConfigurationRepository";
import { LibraryVariableRepository } from "./repositories/libraryVariableRepository";
import { LicenseRepository } from "./repositories/licenseRepository";
import { LifecycleRepository } from "./repositories/lifecycleRepository";
import { LogRepository } from "./repositories/logRepository";
import { LogoRepository } from "./repositories/logoRepository";
import { MachinePolicyRepository } from "./repositories/machinePolicyRepository";
import { MachineRepository } from "./repositories/machineRepository";
import { MachineRoleRepository } from "./repositories/machineRoleRepository";
import { MachineShellsRepository } from "./repositories/machineShellsRepository";
import { MaintenanceConfigurationRepository } from "./repositories/maintenanceConfigurationRepository";
import { OctopusServerNodeRepository } from "./repositories/octopusServerNodeRepository";
import { PackageRepository } from "./repositories/packageRepository";
import { PerformanceConfigurationRepository } from "./repositories/performanceConfigurationRepository";
import { PermissionDescriptionRepository } from "./repositories/permissionDescriptionRepository";
import { PlatformHubConnectionRepository } from "./repositories/platformHubConnectionRepository";
import { PlatformHubRepository } from "./repositories/platformHubRepository";
import { ProgressionRepository } from "./repositories/progressionRepository";
import { ProjectBranchesRepository } from "./repositories/projectBranchesRepository";
import { ProjectDeploymentProcessRepository } from "./repositories/projectDeploymentProcessRepository";
import { ProjectDeploymentSettingsRepository } from "./repositories/projectDeploymentSettingsRepository";
import { ProjectGroupRepository } from "./repositories/projectGroupRepository";
import { ProjectRepository } from "./repositories/projectRepository";
import { ProjectTriggerRepository } from "./repositories/projectTriggerRepository";
import { ProjectVariablesRepository } from "./repositories/projectVariablesRepository";
import { ProxyRepository } from "./repositories/proxyRepository";
import { ReleasesRepository } from "./repositories/releasesRepository";
import { RetentionDefaultConfigurationRepository } from "./repositories/retentionDefaultConfigurationRepository";
import { RunbookProcessRepository } from "./repositories/runbookProcessRepository";
import { RunbookRepository } from "./repositories/runbookRepository";
import { RunbookRunRepository } from "./repositories/runbookRunRepository";
import { RunbookSnapshotRepository } from "./repositories/runbookSnapshotRepository";
import { SchedulerRepository } from "./repositories/schedulerRepository";
import { ScopedUserRoleRepository } from "./repositories/scopedUserRoleRepository";
import { ServerConfigurationRepository } from "./repositories/serverConfigurationRepository";
import { ServerStatusRepository } from "./repositories/serverStatusRepository";
import { ServiceAccountOidcIdentitiesRepository } from "./repositories/serviceAccountOidcIdentitiesRepository";
import { SigningKeyConfigurationRepository } from "./repositories/signingKeyConfigurationRepository";
import { SigningKeyRepository } from "./repositories/signingKeyRepository";
import { SmtpConfigurationRepository } from "./repositories/smtpConfigurationRepository";
import { SpaceRepository } from "./repositories/spaceRepository";
import { StepPackageDeploymentTargetRepository } from "./repositories/stepPackageDeploymentTargetRepository";
import { StepPackageRepository } from "./repositories/stepPackageRepository";
import { SubscriptionRepository } from "./repositories/subscriptionRepository";
import { TagSetRepository } from "./repositories/tagSetRepository";
import { TaskRepository } from "./repositories/taskRepository";
import { TeamMembershipRepository } from "./repositories/teamMembershipRepository";
import { TeamRepository } from "./repositories/teamRepository";
import { TelemetryConfigurationRepository } from "./repositories/telemetryConfigurationRepository";
import { TelemetryRepository } from "./repositories/telemetryRepository";
import { TenantRepository } from "./repositories/tenantRepository";
import { TenantVariableRepository } from "./repositories/tenantVariableRepository";
import { UpgradeConfigurationRepository } from "./repositories/upgradeConfigurationRepository";
import { UserIdentityMetadataRepository } from "./repositories/userIdentityMetadataRepository";
import { UserOnboardingRepository } from "./repositories/userOnboardingRepository";
import { UserPermissionRepository } from "./repositories/userPermissionRepository";
import { UserRepository } from "./repositories/userRepository";
import { UserRoleRepository } from "./repositories/userRoleRepository";
import { VariableRepository } from "./repositories/variableRepository";
import { WorkerRepository } from "./repositories/workerRepository";
import { WorkerShellsRepository } from "./repositories/workerShellsRepository";
import { WorkerToolsRepository } from "./repositories/workerToolsRepository";
import { WorkerPoolsRepository } from "./repositories/workerpoolsRepository";
import type { RouteArgs } from "./resolver";
interface ServerInformation {
    version: string;
}
export interface OctopusCommonRepository {
    Events: EventRepository;
    GitHubApp: GitHubAppRepository;
    Tasks: TaskRepository;
    Teams: TeamRepository;
    ScopedUserRoles: ScopedUserRoleRepository;
    UserPermissions: UserPermissionRepository;
    TeamMembership: TeamMembershipRepository;
    Invitations: InvitationRepository;
    spaceId: string | null;
}
export interface OctopusSystemRepository extends OctopusCommonRepository {
    Authentication: AuthenticationRepository;
    CommunityActionTemplates: CommunityActionTemplateRepository;
    FeaturesConfiguration: FeaturesConfigurationRepository;
    Icons: IconRepository;
    InAppMessaging: InAppMessagingRepository;
    LetsEncryptConfiguration: LetsEncryptConfigurationRepository;
    MaintenanceConfiguration: MaintenanceConfigurationRepository;
    OctopusServerNodes: OctopusServerNodeRepository;
    PerformanceConfiguration: PerformanceConfigurationRepository;
    PermissionDescriptions: PermissionDescriptionRepository;
    PlatformHubRepository: PlatformHubRepository;
    PlatformHubConnectionRepository: PlatformHubConnectionRepository;
    Scheduler: SchedulerRepository;
    ServerConfiguration: ServerConfigurationRepository;
    ServerStatus: ServerStatusRepository;
    Settings: SettingsRepository;
    DeploymentFreezes: DeploymentFreezesRepository;
    SmtpConfiguration: SmtpConfigurationRepository;
    Spaces: SpaceRepository;
    Users: UserRepository;
    UserOnboarding: UserOnboardingRepository;
    UserRoles: UserRoleRepository;
    CloudTemplates: CloudTemplateRepository;
    ExternalSecurityGroupProviders: ExternalSecurityGroupProviderRepository;
    ExternalSecurityGroups: ExternalSecurityGroupRepository;
    ExternalUsers: ExternalUsersRepository;
    Licenses: LicenseRepository;
    UpgradeConfiguration: UpgradeConfigurationRepository;
    UserIdentityMetadata: UserIdentityMetadataRepository;
    getServerInformation: () => ServerInformation;
    StepPackageRepository: StepPackageRepository;
    StepPackageDeploymentTarget: StepPackageDeploymentTargetRepository;
}
export interface OctopusSpaceRepository extends OctopusCommonRepository {
    Accounts: AccountRepository;
    ActionTemplates: ActionTemplateRepository;
    Artifacts: ArtifactRepository;
    Blueprints: BlueprintRepository;
    CertificateConfiguration: CertificateConfigurationRepository;
    Certificates: CertificateRepository;
    Channels: ChannelRepository;
    DashboardConfiguration: DashboardConfigurationRepository;
    Dashboards: DashboardRepository;
    Defects: DefectRepository;
    Deployments: DeploymentRepository;
    DeploymentProcesses: ProjectDeploymentProcessRepository;
    Environments: EnvironmentRepository;
    FeatureToggles: FeatureToggleRepository;
    Feeds: FeedRepository;
    GitCredentials: GitCredentialRepository;
    ImportExport: ImportExportActions;
    InsightsReports: InsightsReportRepository;
    Interruptions: InterruptionRepository;
    KubernetesLiveStatus: KubernetesLiveStatusRepository;
    KubernetesManifest: KubernetesManifestRepository;
    LibraryVariableSets: LibraryVariableRepository;
    Lifecycles: LifecycleRepository;
    MachinePolicies: MachinePolicyRepository;
    MachineRoles: MachineRoleRepository;
    MachineShells: MachineShellsRepository;
    Machines: MachineRepository;
    Runbooks: RunbookRepository;
    RunbookProcess: RunbookProcessRepository;
    RunbookSnapshots: RunbookSnapshotRepository;
    RunbookRuns: RunbookRunRepository;
    Packages: PackageRepository;
    Progression: ProgressionRepository;
    ProjectGroups: ProjectGroupRepository;
    Projects: ProjectRepository;
    ProjectBranches: ProjectBranchesRepository;
    ProjectDeploymentSettings: ProjectDeploymentSettingsRepository;
    ProjectTriggers: ProjectTriggerRepository;
    ProjectVariables: ProjectVariablesRepository;
    Proxies: ProxyRepository;
    Releases: ReleasesRepository;
    Subscriptions: SubscriptionRepository;
    TagSets: TagSetRepository;
    Telemetry: TelemetryRepository;
    Tenants: TenantRepository;
    TenantVariables: TenantVariableRepository;
    Variables: VariableRepository;
    WorkerPools: WorkerPoolsRepository;
    WorkerShells: WorkerShellsRepository;
    Workers: WorkerRepository;
}
// Repositories provide a helpful abstraction around the Octopus Deploy API
export class Repository implements OctopusSpaceRepository, OctopusSystemRepository {
    static readonly takeAll = 2147483647;
    static readonly takeDefaultPageSize = 30; // Only used when we don't rely on the default that's applied server-side.
    Accounts: AccountRepository;
    ActionTemplates: ActionTemplateRepository;
    Artifacts: ArtifactRepository;
    AuditArchive: AuditArchiveRepository;
    AuditStreamConfiguration: AuditStreamConfigurationRepository;
    Authentication: AuthenticationRepository;
    Blueprints: BlueprintRepository;
    BuildInformationRepository: BuildInformationRepository;
    CertificateConfiguration: CertificateConfigurationRepository;
    Certificates: CertificateRepository;
    Channels: ChannelRepository;
    CloudTemplates: CloudTemplateRepository;
    CommunityActionTemplates: CommunityActionTemplateRepository;
    DashboardConfiguration: DashboardConfigurationRepository;
    Dashboards: DashboardRepository;
    Defects: DefectRepository;
    Deployments: DeploymentRepository;
    DeploymentFreezes: DeploymentFreezesRepository;
    DeploymentProcesses: ProjectDeploymentProcessRepository;
    DynamicExtensions: DynamicExtensionRepository;
    DynamicForm: DynamicFormRepository;
    Environments: EnvironmentRepository;
    Events: EventRepository;
    ExperiencesConfiguration: ExperiencesConfigurationRepository;
    ExternalSecurityGroupProviders: ExternalSecurityGroupProviderRepository;
    ExternalSecurityGroups: ExternalSecurityGroupRepository;
    ExternalUsers: ExternalUsersRepository;
    FeaturesConfiguration: FeaturesConfigurationRepository;
    FeatureToggles: FeatureToggleRepository;
    Feeds: FeedRepository;
    Icons: IconRepository;
    ImportExport: ImportExportActions;
    InAppMessaging: InAppMessagingRepository;
    InsightsReports: InsightsReportRepository;
    InstallationHistory: InstallationHistoryRepository;
    Interruptions: InterruptionRepository;
    Invitations: InvitationRepository;
    KubernetesLiveStatus: KubernetesLiveStatusRepository;
    KubernetesManifest: KubernetesManifestRepository;
    LetsEncryptConfiguration: LetsEncryptConfigurationRepository;
    LibraryVariableSets: LibraryVariableRepository;
    Licenses: LicenseRepository;
    Lifecycles: LifecycleRepository;
    Logs: LogRepository;
    Logos: LogoRepository;
    MachinePolicies: MachinePolicyRepository;
    MachineRoles: MachineRoleRepository;
    MachineShells: MachineShellsRepository;
    Machines: MachineRepository;
    MaintenanceConfiguration: MaintenanceConfigurationRepository;
    OctopusServerNodes: OctopusServerNodeRepository;
    RetentionDefaultConfiguration: RetentionDefaultConfigurationRepository;
    Runbooks: RunbookRepository;
    RunbookProcess: RunbookProcessRepository;
    RunbookSnapshots: RunbookSnapshotRepository;
    RunbookRuns: RunbookRunRepository;
    Packages: PackageRepository;
    PerformanceConfiguration: PerformanceConfigurationRepository;
    PermissionDescriptions: PermissionDescriptionRepository;
    PlatformHubRepository: PlatformHubRepository;
    PlatformHubConnectionRepository: PlatformHubConnectionRepository;
    Progression: ProgressionRepository;
    ProjectGroups: ProjectGroupRepository;
    Projects: ProjectRepository;
    ProjectBranches: ProjectBranchesRepository;
    ProjectDeploymentSettings: ProjectDeploymentSettingsRepository;
    ProjectTriggers: ProjectTriggerRepository;
    ProjectVariables: ProjectVariablesRepository;
    Proxies: ProxyRepository;
    Releases: ReleasesRepository;
    ScopedUserRoles: ScopedUserRoleRepository;
    Scheduler: SchedulerRepository;
    ServerStatus: ServerStatusRepository;
    ServerConfiguration: ServerConfigurationRepository;
    Settings: SettingsRepository;
    SmtpConfiguration: SmtpConfigurationRepository;
    Spaces: SpaceRepository;
    Subscriptions: SubscriptionRepository;
    TagSets: TagSetRepository;
    Tasks: TaskRepository;
    Teams: TeamRepository;
    Tenants: TenantRepository;
    TenantVariables: TenantVariableRepository;
    Telemetry: TelemetryRepository;
    TelemetryConfiguration: TelemetryConfigurationRepository;
    UpgradeConfiguration: UpgradeConfigurationRepository;
    UserIdentityMetadata: UserIdentityMetadataRepository;
    UserRoles: UserRoleRepository;
    UserPermissions: UserPermissionRepository;
    TeamMembership: TeamMembershipRepository;
    Users: UserRepository;
    UserOnboarding: UserOnboardingRepository;
    Variables: VariableRepository;
    getServerInformation: () => ServerInformation;
    WorkerPools: WorkerPoolsRepository;
    WorkerShells: WorkerShellsRepository;
    WorkerTools: WorkerToolsRepository;
    Workers: WorkerRepository;
    StepPackageRepository: StepPackageRepository;
    StepPackageDeploymentTarget: StepPackageDeploymentTargetRepository;
    GitCredentials: GitCredentialRepository;
    GitHubApp: GitHubAppRepository;
    Deprecations: DeprecationRepository;
    Diagnostics: DiagnosticsRepository;
    ServiceAccountOidcIdentities: ServiceAccountOidcIdentitiesRepository;
    SigningKeys: SigningKeyRepository;
    SigningKeyConfiguration: SigningKeyConfigurationRepository;
    constructor(private readonly client: Client, private readonly requestContext: RequestContext) {
        this.Accounts = new AccountRepository(client, requestContext);
        this.ActionTemplates = new ActionTemplateRepository(client, requestContext);
        this.Artifacts = new ArtifactRepository(client, requestContext);
        this.Authentication = new AuthenticationRepository(client, requestContext);
        this.AuditArchive = new AuditArchiveRepository(client, requestContext);
        this.AuditStreamConfiguration = new AuditStreamConfigurationRepository(client, requestContext);
        this.Blueprints = new BlueprintRepository(client, requestContext);
        this.BuildInformationRepository = new BuildInformationRepository(client, requestContext);
        this.CertificateConfiguration = new CertificateConfigurationRepository(client, requestContext);
        this.Certificates = new CertificateRepository(client, requestContext);
        this.CloudTemplates = new CloudTemplateRepository(client, requestContext);
        this.CommunityActionTemplates = new CommunityActionTemplateRepository(client, requestContext);
        this.DashboardConfiguration = new DashboardConfigurationRepository(client, requestContext);
        this.Dashboards = new DashboardRepository(client, requestContext);
        this.Defects = new DefectRepository(client, requestContext);
        this.Deployments = new DeploymentRepository(client, requestContext);
        this.DeploymentFreezes = new DeploymentFreezesRepository(client, requestContext);
        this.DeploymentProcesses = new ProjectDeploymentProcessRepository(client, requestContext);
        this.Diagnostics = new DiagnosticsRepository(client, requestContext);
        this.DynamicExtensions = new DynamicExtensionRepository(client, requestContext);
        this.DynamicForm = new DynamicFormRepository(client, requestContext);
        this.Environments = new EnvironmentRepository(client, requestContext);
        this.Events = new EventRepository(client, requestContext);
        this.ExperiencesConfiguration = new ExperiencesConfigurationRepository(client, requestContext);
        this.ExternalSecurityGroupProviders = new ExternalSecurityGroupProviderRepository(client, requestContext);
        this.ExternalSecurityGroups = new ExternalSecurityGroupRepository(client, requestContext);
        this.ExternalUsers = new ExternalUsersRepository(client, requestContext);
        this.FeaturesConfiguration = new FeaturesConfigurationRepository(client, requestContext);
        this.FeatureToggles = new FeatureToggleRepository(client, requestContext);
        this.Feeds = new FeedRepository(client, requestContext);
        this.Icons = new IconRepository(client, requestContext);
        this.ImportExport = new ImportExportActions(client, requestContext);
        this.InAppMessaging = new InAppMessagingRepository(client, requestContext);
        this.InsightsReports = new InsightsReportRepository(client, requestContext);
        this.InstallationHistory = new InstallationHistoryRepository(client, requestContext);
        this.Interruptions = new InterruptionRepository(client, requestContext);
        this.Invitations = new InvitationRepository(client, requestContext);
        this.KubernetesLiveStatus = new KubernetesLiveStatusRepository(client, requestContext);
        this.KubernetesManifest = new KubernetesManifestRepository(client, requestContext);
        this.LetsEncryptConfiguration = new LetsEncryptConfigurationRepository(client, requestContext);
        this.LibraryVariableSets = new LibraryVariableRepository(client, requestContext);
        this.Licenses = new LicenseRepository(client, requestContext);
        this.Lifecycles = new LifecycleRepository(client, requestContext);
        this.Logs = new LogRepository(client, requestContext);
        this.Logos = new LogoRepository(client, requestContext);
        this.MachinePolicies = new MachinePolicyRepository(client, requestContext);
        this.MachineRoles = new MachineRoleRepository(client, requestContext);
        this.MachineShells = new MachineShellsRepository(client, requestContext);
        this.Machines = new MachineRepository(client, requestContext);
        this.MaintenanceConfiguration = new MaintenanceConfigurationRepository(client, requestContext);
        this.OctopusServerNodes = new OctopusServerNodeRepository(client, requestContext);
        this.RetentionDefaultConfiguration = new RetentionDefaultConfigurationRepository(client, requestContext);
        this.Runbooks = new RunbookRepository(client, requestContext);
        this.RunbookProcess = new RunbookProcessRepository(client, requestContext);
        this.RunbookSnapshots = new RunbookSnapshotRepository(client, requestContext);
        this.RunbookRuns = new RunbookRunRepository(client, requestContext);
        this.Packages = new PackageRepository(client, requestContext);
        this.PerformanceConfiguration = new PerformanceConfigurationRepository(client, requestContext);
        this.PermissionDescriptions = new PermissionDescriptionRepository(client, requestContext);
        this.PlatformHubConnectionRepository = new PlatformHubConnectionRepository(client, requestContext);
        this.PlatformHubRepository = new PlatformHubRepository(client, requestContext);
        this.Progression = new ProgressionRepository(client, requestContext);
        this.ProjectGroups = new ProjectGroupRepository(client, requestContext);
        this.Projects = new ProjectRepository(client, requestContext);
        this.ProjectBranches = new ProjectBranchesRepository(client, requestContext);
        this.ProjectDeploymentSettings = new ProjectDeploymentSettingsRepository(client, requestContext);
        this.Channels = new ChannelRepository(this.Projects, client, requestContext);
        this.ProjectTriggers = new ProjectTriggerRepository(client, requestContext);
        this.ProjectVariables = new ProjectVariablesRepository(client, requestContext);
        this.Proxies = new ProxyRepository(client, requestContext);
        this.Releases = new ReleasesRepository(client, requestContext);
        this.Scheduler = new SchedulerRepository(client, requestContext);
        this.ScopedUserRoles = new ScopedUserRoleRepository(client, requestContext);
        this.ServerStatus = new ServerStatusRepository(client, requestContext);
        this.ServerConfiguration = new ServerConfigurationRepository(client, requestContext);
        this.Settings = new SettingsRepository(client, requestContext);
        this.SmtpConfiguration = new SmtpConfigurationRepository(client, requestContext);
        this.Spaces = new SpaceRepository(client, requestContext);
        this.Subscriptions = new SubscriptionRepository(client, requestContext);
        this.TagSets = new TagSetRepository(client, requestContext);
        this.Tasks = new TaskRepository(client, requestContext);
        this.Teams = new TeamRepository(client, requestContext);
        this.Tenants = new TenantRepository(client, requestContext);
        this.TenantVariables = new TenantVariableRepository(client, requestContext);
        this.UpgradeConfiguration = new UpgradeConfigurationRepository(client, requestContext);
        this.UserIdentityMetadata = new UserIdentityMetadataRepository(client, requestContext);
        this.UserPermissions = new UserPermissionRepository(client, requestContext);
        this.TeamMembership = new TeamMembershipRepository(client, requestContext);
        this.Telemetry = new TelemetryRepository(client, requestContext);
        this.TelemetryConfiguration = new TelemetryConfigurationRepository(client, requestContext);
        this.UserRoles = new UserRoleRepository(client, requestContext);
        this.Users = new UserRepository(client, requestContext);
        this.UserOnboarding = new UserOnboardingRepository(client, requestContext);
        this.Variables = new VariableRepository(client, requestContext);
        this.getServerInformation = client.getServerInformation.bind(client);
        this.WorkerPools = new WorkerPoolsRepository(client, requestContext);
        this.WorkerShells = new WorkerShellsRepository(client, requestContext);
        this.WorkerTools = new WorkerToolsRepository(client, requestContext);
        this.Workers = new WorkerRepository(client, requestContext);
        this.StepPackageRepository = new StepPackageRepository(client, requestContext);
        this.StepPackageDeploymentTarget = new StepPackageDeploymentTargetRepository(client, requestContext);
        this.GitCredentials = new GitCredentialRepository(client, requestContext);
        this.GitHubApp = new GitHubAppRepository(client, requestContext);
        this.Deprecations = new DeprecationRepository(client, requestContext);
        this.ServiceAccountOidcIdentities = new ServiceAccountOidcIdentitiesRepository(client, requestContext);
        this.SigningKeys = new SigningKeyRepository(client, requestContext);
        this.SigningKeyConfiguration = new SigningKeyConfigurationRepository(client, requestContext);
    }
    public resolve = (path: string, uriTemplateParameters?: RouteArgs) => this.client.resolve(path, uriTemplateParameters);
    public get spaceId(): string | null {
        return this.client.spaceId;
    }
    switchToSpace(spaceId: string): Promise<void> {
        return this.client.switchToSpace(this.requestContext, spaceId);
    }
    switchToSystem(): void {
        this.client.switchToSystem();
    }
    async forSpace(spaceId: string): Promise<OctopusSpaceRepository> {
        if (this.spaceId !== spaceId) {
            return new Repository(await this.client.forSpace(this.requestContext, spaceId), this.requestContext);
        }
        return this;
    }
    forSystem(): OctopusSystemRepository {
        return new Repository(this.client.forSystem(), this.requestContext);
    }
}
