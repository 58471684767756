import type { Client, RequestContext } from "../client";
import type { GetLiveStatusRollupBffResponse } from "../resources/getLiveStatusRollupBffResponse";
import type { KubernetesLiveStatusResponse } from "../resources/kubernetesLiveStatusResponse";
export class KubernetesLiveStatusRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    getForApplicationInstance(projectId: string, environmentId: string, tenantId?: string) {
        if (tenantId === undefined)
            return this.client.get<KubernetesLiveStatusResponse>(this.requestContext, "~/api/spaces/{spaceId}/projects/{projectId}/environments/{environmentId}/untenanted/livestatus", {
                projectId: projectId,
                environmentId: environmentId,
            });
        return this.client.get<KubernetesLiveStatusResponse>(this.requestContext, "~/api/spaces/{spaceId}/projects/{projectId}/environments/{environmentId}/tenants/{tenantId}/livestatus", {
            projectId: projectId,
            environmentId: environmentId,
            tenantId: tenantId,
        });
    }
    getRollupForApplicationInstance(projectId: string, environmentId: string, tenantId?: string): Promise<GetLiveStatusRollupBffResponse> {
        if (tenantId === undefined) {
            return this.client.get<GetLiveStatusRollupBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/environments/{environmentId}/untenanted/livestatus/rollup", {
                projectId: projectId,
                environmentId: environmentId,
            });
        }
        return this.client.get<GetLiveStatusRollupBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/environments/{environmentId}/tenants/{tenantId}/livestatus/rollup", {
            projectId: projectId,
            environmentId: environmentId,
            tenantId: tenantId,
        });
    }
}
