import type { Client, RequestContext } from "../client";
import type { LibraryVariableSetResource, NewLibraryVariableSetResource, VariableSetContentType } from "../resources/libraryVariableSetResource";
import type { LibraryVariableSetUsageResource } from "../resources/libraryVariableSetUsageResource";
import type { AllArgs, ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";
type LibraryVariableAllArgs = {
    contentType?: VariableSetContentType;
} & AllArgs;
type LibraryVariableListArgs = {
    contentType?: VariableSetContentType;
    partialName?: string;
} & ListArgs;
export class LibraryVariableRepository extends BasicRepository<LibraryVariableSetResource, NewLibraryVariableSetResource, LibraryVariableListArgs, LibraryVariableAllArgs> {
    constructor(client: Client, requestContext: RequestContext) {
        super("LibraryVariables", client, requestContext);
    }
    getUsages(libraryVariableSet: LibraryVariableSetResource): Promise<LibraryVariableSetUsageResource> {
        return this.client.get(this.requestContext, libraryVariableSet.Links["Usages"]);
    }
}
